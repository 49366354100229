import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import Description from "../components/description.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const About = ({ data }) => {
  return (
    <Layout pageTitle="About">
      {/* <h1 className="main__page-title">About</h1> */}
      <div className="row mb-5">
        <p>I built this site for a few reasons...</p>
        <p>I’ve been working as a web developer in one form or another for more than 
          twenty years. Would you trust a mechanic who doesn’t have their own car? 
          Maybe. I figured it couldn’t hurt to put some effort into a personal project 
          to go along with my professional work.</p>
        <p>Speaking of work, there isn’t always time to try new things when I’m on the 
          clock. Think of this site as a professional sandbox. For example, this site 
          was created with <a href="https://www.gatsbyjs.com/" target="about-gatsby">
          Gatsby</a> and <a href="https://www.drupal.org/" target="about-drupal">
          Drupal</a>. I work on Drupal sites in my full-time gig, and I wanted to 
          learn more about static site generators.</p>
        <p>Finally—and I’ve said this before, but I feel it’s worth repeating: I’m not 
          a visionary, or a rock star in my field. I’m good at my job, and I love what 
          I do. So, why do I need this outlet to share my thoughts and examples of my 
          work? I’m doing this, at least in part, for my wife. She’s heard all my 
          stories. Having been together for more than two decades, she needs this as 
          much as I do.</p>
        <p>So, who am I?</p>
        <p>I’m a web developer, a geographer, and an artist. I want to be a painter, 
          traveling to exotic locales and studying the landscape from behind an easel,
          (note to self: buy an easel). Or, maybe a musician, playing and singing my 
          heart out. I want to have my own line of handmade, one-of-a-kind jewelry, 
          (I even have a name for my brand.) I want to try Krav Maga, or Jiu 
          Jistu--except the pandemic has put me off those types of activities for the 
          time being. I love being outdoors, but I also love video games. And Legos—any 
          Legos.</p>
        <p>I love to learn, I love to try new things.* I don’t like to discuss 
          politics. I like to watch sports with my wife because she doesn’t mind when 
          I ask her to explain the rules to me. That’s who I am. Nice to meet you.</p>
        <p><em style={{ fontSize: "0.875rem" }}>* Within reason. I mean, I’m going to 
        try new things that appeal to me. For example, I don’t like fish. So, I won’t 
        be trying sushi. Raw fish—hard pass.</em></p>
      </div>
      <div className="row">
        <div className="col-12 mt-3 mb-5">
          <h2 className="section-title">Professional and Academic Experience</h2>
        </div>
      </div>
      <div className="row">
        {/* <section className="about-list col-12 col-md-6 col-lg-7 mb-4 mb-md-0"> */}
        <section className="about-list col-12 mb-4 mb-md-0">
          <h3 className="about-list__header m-0"><FontAwesomeIcon className="fa-icon" icon={["fas", "briefcase"]} />&nbsp;Jobs</h3>
          {data.jobs.edges.map(({ node }) => ( 
            <div className="experience-item mt-5" key={node.id}>
              <section className="experience-item__header">
                <h4 className="experience-item__header__employer">
                  <span className="experience-item__employer__name">{node.relationships.employer.name}</span><span>,&nbsp;</span>
                  <span className="experience-item__employer__location-city ml-0 mr-auto">{node.location.city}, {node.location.state}</span>
                </h4>
                <h5 className="experience-item__header__position">
                  {node.positions.map((position, index) => (
                    <div key={index}>{position}</div>
                  ))}
                  {/* <p className="experience-item__header__position">{node.positions.join(`, `)}</p> */}
                </h5>
              </section>
              <div className="margin-fix" aria-hidden="true"></div>
              {/* {node.preamble !== null && <div className="bg-light mt-5 px-4 pt-3 pb-1" dangerouslySetInnerHTML={{ __html: node.preamble.html}}></div>} */}
              {node.preamble !== null && <Description className="content-preamble bg-light mt-4 px-4 pt-3 pb-1" html={node.preamble.html} />}
              {/* {node.description !== null && <div className="bg-light mt-5 px-4 pt-3 pb-1" dangerouslySetInnerHTML = {{ __html: node.description.html}}></div>} */}
              {node.description !== null && <Description className="content-description bg-light mt-4 px-4 pb-3" html={node.description.html} />}
            </div>
          ))}
        </section>
        {/* <section className="about-list col-12 col-md-5 offset-md-1 col-lg-4 mt-5 mt-md-0"> */}
        <section className="about-list col-12 mt-5 mt-md-0">
          <h3 className="about-list__header m-0"><FontAwesomeIcon className="fa-icon" icon={["fas", "graduation-cap"]} />&nbsp;Education</h3>
          {data.degrees.edges.map(({ node }) => (
            <div className="degree-item mt-5" key={node.id}>
              <h4 className="degree-item__degree-type">{node.degree_type}</h4>
              <h5 className="degree-item__institution-date">{node.relationships.institution.name}, {node.year_received}</h5>
              <h5 className="degree-item__location-city">{node.location.city}, {node.location.state}</h5>
            </div>
          ))}
        </section>
      </div>
    </Layout>
  )
}

export default About

export const query = graphql `
query {
  degrees: allNodeEducation (
      sort: { order: DESC, fields: field_sorting_weight }
    ) {
      edges {
        node {
          id
          degree_type: field_edu_degree
          relationships {
            institution: field_edu_institution {
              name
            }
          }
          year_received: field_edu_year_received
          location: field_location_city {
            city: location_city_city
            state: location_city_state
          }
        }
      }
    }
    jobs: allNodeExperience (
      sort: { order: DESC, fields: field_sorting_weight }
    ) {
      edges {
        node {
          id
          positions: field_exp_position_title
          relationships {
            employer: field_exp_employer {
              name
            }
          }
          location: field_location_city {
            city: location_city_city
            state: location_city_state
          }
          start_date: field_start_date {
            month: month_year_month
            year: month_year_year
          }
          end_date: field_end_date {
            month: month_year_month
            year: month_year_year
          }
          preamble: field_preamble_formatted {
            html: processed
          }
          description: field_description_formatted {
            html: processed
          }
        }
      }
    }
  }
`